import React from 'react';
export default function InstagramLink() {
    return(
        <div>
        <a
        href="https://www.instagram.com/photomarkeilat/"
        className="instagram_float"
        target="_blank"
        rel="noopener noreferrer"
        >
        <i className="fa fa-instagram instagram-icon"></i>
      </a>
        </div>
        
      
    )
}
