import React from "react";
import styled from "styled-components";
// Components

// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/pic/pic2.jpeg";
import AddImage3 from "../../assets/img/pic/pic3.jpeg";
import AddImage4 from "../../assets/img/pic/pic4.JPG";
import AddImage5 from "../../assets/img/pic/pic5.JPG";

export default function Pictures() {
  return (
    <Wrapper id="pictures">
      <div className="lightBg">
        <div className="container">
        <Advertising className="flexCenter">
        <AddRight>
              <h2 className="font40 semiBold">צילום תמונות סטילס אורך ורוחב</h2>
              <p className="font20">
                שיראו את כל הגוף, לא רק את הפנים!
              </p>
              </AddRight>
            <AddLeft>
            <AddLeftInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp4 >
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                    <AddImgWrapp5>
                      <img src={AddImage5} alt="office" />
                    </AddImgWrapp5>
                  </div>
                </AddLeftInner>
            </AddLeft>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 0px 1px;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 10px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddRight = styled.div`
padding: 2% 0;
width: 50%;
text-align: center;
@media (max-width: 860px) {
  width: 80%;
  order: 2;
}
`;
const AddLeft = styled.div`
  padding: 2% 3%;
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const AddLeftInner = styled.div`
  width: 110%;

`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 110%;
    transform: rotate(-10deg);
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp3 = styled.div`
  width: 30%;
  margin: 0 8% 10px 5%;
  img {
    width: 120%;
    transform: rotate(-40deg);
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp4 = styled.div`
  width: 20%;
  margin-left: 20%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
    transform: rotate(8deg);
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp5 = styled.div`
  width: 30%;
  margin: 0 5% auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
