import React from "react";
import styled from "styled-components";
// Components

// Assets
import Cam1 from "../../assets/img/add/cam.png";

export default function Camera() {
  return (
    <Wrapper id="camera">
      <div className="lightBg">
        <div className="container">
        <Advertising className="flexCenter">
          <AddRight>
            <ImgWrapper className="flexCenter">
                    <img className="radius6" src={Cam1} alt="add" />
            </ImgWrapper>
          </AddRight>
          <AddLeft>
            <h2 className="font40 semiBold">עמדת צילום בעיצוב רטרו מדהים</h2>
            <p className="font20">
            מתערבים איתכם שלא נתקלתם בכזו בשום אירוע!
            </p>
          </AddLeft>
        </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`

  width: 100%;
  hight: 1px;
`;

const Advertising = styled.div`
  position: relative;
  @media (max-width: 1160px) {
    padding: 0 10px 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 10px 0;
    margin: 80px 0 0px 0;
    
  }
`;

const AddRight = styled.div`
  position: relative;
  margin: -100px 0 0px 0;
  width: 60%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
  }
`;
const AddLeft = styled.div`
  padding: 2% 0;
  width: 50%;
  text-align: center;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

