import React from "react";
// Sections
import Header from "../components/Sections/Header";
import Camera from "../components/Sections/Camera";
import Pictures from "../components/Sections/Pictures";
import VideosAndGifs from "../components/Sections/VideosAndGifs";
import LiveSend from "../components/Sections/LiveSend";
import Printing from "../components/Sections/Printing";
import Interactive from "../components/Sections/Interactive";
import Gallery from "../components/Sections/Gallery";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Call from "../components/Elements/Call.js";
import Whatsapp from "../components/Elements/Whatsapp.js";
import FacebookLink from "../components/Elements/FacebookLink.js";
import InstagramLink from "../components/Elements/InstagramLink.js";



export default function Landing() {
  return (
    <>
      {/* <TopNavbar /> */}
      <Header />
      <Camera />
      <Interactive />
      <Pictures />
      <VideosAndGifs />
      <Printing /> 
      <LiveSend />
      <Gallery />
      {/* <Contact /> */}
      <Footer />
      <InstagramLink />
      <FacebookLink />
      <Call />
      <Whatsapp />
    </>
  );
}


