import React from "react";
import styled from "styled-components";
// Components
// Assets
import Print from "../../assets/img/add/add2.png";

export default function Gallery() {
  return (
    <Wrapper id="gallery">
      <div className="lightBg">
        <div className="container">
        <Advertising className="flexCenter">
        <AddRight>
              <h2 className="font40 semiBold">גלריית תמונות אונליין</h2>
              <p className="font20">
                כדי שתכלו לדאוג ששום דבר לא נשאר מאחור
              </p>
              </AddRight>
            <AddLeft>
            <AddLeftInner>
                <ImgWrapper className="flexCenter">
                      <img className="radius11" src={Print} alt="add" />
                </ImgWrapper>
                </AddLeftInner>
            </AddLeft>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 0px 1px;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 1px 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 10px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddRight = styled.div`
padding: 2% 0;
width: 50%;
text-align: center;
@media (max-width: 860px) {
  width: 80%;
  order: 2;
}
`;
const AddLeft = styled.div`
  padding: 2% 0;
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 1;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;

`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
    transform: rotate(-7deg);
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;