import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">בואו נשאר בקשר...</h1>

          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font30">שם פרטי:</label>
                <input type="text" id="fname" name="fname" className="font25 extraBold" />
                <label className="font30">דוא"ל:</label>
                <input type="text" id="email" name="email" className="font25 extraBold" />
                <label className="font30">הודעה:</label>
                <input type="text" id="subject" name="subject" className="font25 extraBold" />
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="שלח" className="font20 pointer animate radius8" style={{ maxWidth: "220px" }} />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "100%" }} className="flexNullCenter flexColumn">
                <ContactImgBox >
                  {/* <img src={ContactImg1} alt="office" className="radius6" /> */}
                  <img style={{ width: "30vh" }} src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
direction:rtl;
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`

  border: 2px solid #fff;
  background-color: #fff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #000;
  :hover {
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  
  max-width: 1220px; 
  align-self: flex-center; 
  margin: 20px 0px 0 120px;
  @media (max-width: 860px) {
    display: none;
  }
`;
const SumbitWrapper = styled.div`
  height: 50px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 60px;
    
  }
`;









