import React from "react";
import styled from "styled-components";
// Components
// Assets
import HeaderImage from "../../assets/img/logo.png";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 style={{direction: "ltr"}} className="extraBold font60">PEOPLE MAKE BRANDS MAKE PEOPLE!</h1>
          <br/>
          <h1 className="regular font60">סוכנות הצילום האינטראקטיבית שמחברת מותגים לאנשים</h1>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" />
          <DotsWrapper style={{left:'15px'}}>
            {/* <Dots /> */}
          </DotsWrapper>
        </ImageWrapper >
        
      </RightSide>
    </Wrapper>
  );
}




const Wrapper = styled.section`
  padding: 100px 5px;
  width: 100%;
  
  @media (max-width: 770px) {
    padding: 0px 5px;
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    width: 80%;
    padding: 0px 5px;
    flex-direction: column;
  }
`;





const LeftSide = styled.div`
  width: 100%;
  padding: 0 5px;
  @media (max-width: 770px) {
    margin: 10px 0 10px 0;
    width: 100%;
    order: 2;
    text-align: center;
    h1 {
      line-height: 1.1;
    }
  }
  @media (max-width: 1024px) {
    margin: 10px 0 10px 0;
    width: 100%;
    order: 2;
    text-align: center;
    h1 {
      line-height: 1.1;
    }
  }
`;
const RightSide = styled.div`
padding-right: 10%;
  width: 100%;
  @media (max-width: 770px) {
    padding: 1px 15px;
    order: 1;
    margin-top: 1px;
    margin-bottom: 30px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    order: 1;
    margin-top: 1px;
    margin-bottom: 30px;
  }
`;

const ImageWrapper = styled.div`

  display: flex;
  justify-content:flex-start;
  position: relative;
  z-index: 9;
  @media (max-width: 770px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 770px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    width: 50%;
  }
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


