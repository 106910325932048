import React from 'react';

export default function Call() {
    return(
        <div>
      <a
        href="tel:0526431234"
        className="call_float"
        target="_blank"
        rel="noopener noreferrer"
        >        
        <i className="fa fa-phone phone-icon"></i>
      </a>
        </div>
        
      
    )
}
