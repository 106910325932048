import * as React from "react";
import { ReactComponent as Logo } from './logo_p.svg';

function SvgComponent(props) {
  return (
    <div>
      
      <svg width={100} height={100} viewBox="3 -2 15 40" {...props}>
      <Logo width={27} height={40}/>
    </svg>
    </div>

  );
}

export default SvgComponent;
