import React from "react";
import styled from "styled-components";
// Components

// Assets
import LiveS from "../../assets/img/livesend.JPG";

export default function LiveSend() {
  return (
    <Wrapper id="liveSend">
      <div className="whiteBg">
        <div className="container">
        <Advertising className="flexCenter">
          <AddRight>
            <ImgWrapper className="flexCenter">
                    <img className="radius6" src={LiveS} alt="add" />
            </ImgWrapper>
          </AddRight>
          <AddLeft>
            <h2 className="font40 semiBold">שליחת תמונות או ווידאו יישירות לנייד בזמן אמת</h2>
            <p className="font20">
            כי הצלם סטילס לא באמת שולח לאנשים את התמונות!
            </p>
          </AddLeft>
        </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 0px 1px;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 10px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddRight = styled.div`
  padding: 2% 3%;
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    padding: 5% ;
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddLeft = styled.div`
padding: 2% 0;
width: 50%;
text-align: center;
@media (max-width: 860px) {
  width: 80%;
  order: 2;
}
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
    transform: rotate(8deg);
  }
  @media (max-width: 400px) {
    padding: 10%;
  }
`;

