import React from "react";
import styled from "styled-components";
// Components
// Assets
import Addgif1 from "../../assets/img/videogifs/gif1.gif";
import Addgif2 from "../../assets/img/videogifs/gif2.gif";
import Addgif3 from "../../assets/img/videogifs/gif3.gif";
import Addgif4 from "../../assets/img/videogifs/gif4.gif";

export default function VideosAndGifs() {
  return (
    <Wrapper id="videosAndGifs">
      <div className="whiteBg">
        <div className="container">
        <Advertising className="flexCenter">
        <AddRight>
        <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={Addgif1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={Addgif2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={Addgif3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={Addgif4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>

              </AddRight>
            <AddLeft>
            <h2 className="font40 semiBold">צילום סרטוני בומרג וגיף</h2>
              <p className="font20">
                שיהיה לאורחים שלכם משהו מגניב לשתף גם בסטורי
              </p>

            </AddLeft>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 0px 1px;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 10px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddRight = styled.div`
padding: 2% 0;
width: 50%;
text-align: center;
@media (max-width: 860px) {
  width: 80%;
  order: 2;
}
`;
const AddLeft = styled.div`
  padding: 2% 0;
  width: 50%;
  text-align: center;
  @media (max-width: 860px) {
    width: 80%;
    order: 1;
  }
`;

const AddRightInner = styled.div`
  width: 110%;

`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    transform: rotate(-5deg);
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
    transform: rotate(8deg);
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 120%;
    height: auto;
    border-radius: 0.3rem;
    filter: drop-shadow(0 6mm 4mm rgb(0, 0, 0));
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
